<template>
  <v-dialog v-model="active" width="600">
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData"></slot>
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName"></slot>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Share ScotsWork Data with Other Employees</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="active = false">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p class="mt-2">You can share this list with other employees who may have a part in helping to oversee your student workers. This will give them access to the timeclock data, their work status, and the joint class schedule.</p>
        <v-row>
          <v-col :cols="4">
            <v-card elevation="2" style="height:300px">
              <v-list>
                <v-list-item-group v-model="selected">
                  <v-list-item v-for="{ _id, name } in placements" :key="_id">
                    <v-list-item-title>{{ name }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col :cols="8">
            <v-card elevation="2" style="height:300px">
              <v-toolbar>
                <directory-search v-model="userSearch" :person-filter="['Faculty', 'Staff']" label="Employee Search" value-field="pidm" outlined hide-details></directory-search>
                <v-btn :disabled="userSearch == null || userSearch === ''" color="success" @click="addUser">
                  <v-icon left>fas fa-plus-circle</v-icon>
                  Add User
                </v-btn>
              </v-toolbar>
              <v-list>
                <v-list-item v-for="{ pidm, name } in selectedPlacement" :key="pidm">
                  <v-list-item-title>{{ name }}</v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon @click="removeUser(pidm)">
                      <v-icon color="error">fal fa-trash</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'

export default {
  components: {
    DirectorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const active = ref(false)
    const panel = ref(0)
    const placements = ref([])
    const selected = ref('')
    const selectedIndex = computed(() => placements.value.findIndex(({ _id }) => _id === selected.value))
    const selectedPlacement = computed(() => {
      const rec = placements.value.find(({ _id }) => _id === selected.value)
      if (rec) return rec.users
      else return []
    })

    const service = root.$feathers.service('work-study/placements')

    async function loadData () {
      const aggregate = [
        { $match: { owner: user.value.pidm } },
        {
          $lookup: {
            from: 'Directory',
            localField: 'access',
            foreignField: 'pidm',
            as: 'users',
            pipeline: [ { $project: { pidm: 1, name: { $concat: [ '$name.first', ' ', '$name.last' ] } } } ]
          }
        }
      ]
      const { data } = await service.find({ query: { aggregate } })
      placements.value = data
    }

    onMounted(async () => {
      loadData()
    })

    async function removeUser (pidm) {
      // Load the current list so we can remove them from the array and then patch the array without this pidm
      const { access } = await service.get(selected.value)
      const index = access.findIndex((rec) => rec === pidm)
      if (index >= 0) {
        access.splice(index, 1)
        await service.patch(selected.value, { access })
      }
      await loadData()
    }

    const userSearch = ref('')
    async function addUser () {
      const pidm = userSearch.value
      // Load the current list so we can make sure they are not on the list (just in case we are out of sync)
      const { access } = await service.get(selected.value)
      // Only add them if they are not already in the list
      if (!access.includes(pidm)) {
        await service.patch(selected.value, { $push: { access: pidm } })
      }
      await loadData()
    }

    return {
      user,
      active,
      panel,
      placements,
      selected,
      selectedIndex,
      selectedPlacement,
      removeUser,
      userSearch,
      addUser
    }
  }
}
</script>
